// const BASE_URL = "http://192.168.6.23:5000/";
// const BASE_URL = "http://192.168.10.40:5017/";
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.infinitemlmsoftware.com/";


//LOCAL 
// const BASE_URL = "http://192.168.10.40:5004/api/";

// DEMO
// const BASE_URL = "http://162.19.146.135:1588/api/";

const BASE_URL = "https://communityblessed.org/api/"

const DEFAULT_KEY = '8be61683-b0a2-47da-a81d-fd5a7a5227bb'

const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
