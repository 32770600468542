import React from "react";
import { useTranslation } from "react-i18next";

const ChildrenAccounts = ({ details }) => {
  const { t } = useTranslation();

  return (
    <div id="firstTab" className="tabcontent">
      {details?.map((item) => {
        return <p>{t("username")} : {item.username}</p>;
      })}
    </div>
  );
};

export default ChildrenAccounts;
