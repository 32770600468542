import React, { useState, useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { ApiHook } from "../../hooks/apiHook";
import BoardTreeComponent from "../../components/Tree/board-tree/BoardTreeComponent";
import Loader from "react-js-loader";
import "../../assests/css/board.css";
import { useParams, useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useTranslation } from "react-i18next";

const BoardTree = () => {
  const { t } = useTranslation();
  const { boardId, confirm } = useParams();
  const navigate = useNavigate();
  const [currentBoardId, setCurrentBoardId] = useState(() => {
    const parsedId = parseInt(boardId, 10);
    return isNaN(parsedId) ? 1 : parsedId;
  });

  const getBoardTree = ApiHook.CallBoardTree(currentBoardId);
  const highestRank = getBoardTree?.data?.data?.highestRank || 1;

  useEffect(() => {
    const path = confirm
      ? `/board-view/${currentBoardId}/${confirm}`
      : `/board-view/${currentBoardId}`;
    navigate(path);
  }, [currentBoardId, navigate, confirm]);

  const handleNavigation = (direction) => {
    setCurrentBoardId((prevId) => {
      const newId = direction === "next" ? prevId + 1 : prevId - 1;
      return Math.min(Math.max(newId, 1), highestRank);
    });
  };

  return (
    <>
      {getBoardTree.isLoading && (
        <div className="centered-loader">
          <Loader
            type="bubble-scale"
            bgColor={"#954cea"}
            color={"#ffffff"}
            size={50}
          />
        </div>
      )}
      {getBoardTree.data && (
        <BoardTreeComponent
          treeData={getBoardTree?.data?.data?.constructedBoardTree}
          currentBoardId={currentBoardId}
          image={getBoardTree?.data?.data?.image}
          confirm={confirm}
          currentUserId={getBoardTree?.data?.data?.currentUserId}
        />
      )}
      <div
        className="navigation-container"
        style={{ position: "relative", zIndex: 999 }}
      >
        <button
          onClick={() => handleNavigation("prev")}
          disabled={currentBoardId <= 1}
          className="nav-button"
          style={{ cursor: "pointer" }}
        >
          <ChevronLeft size={24} />
        </button>
        <span className="text-center">
          {t("board")}: {currentBoardId}
        </span>
        <button
          onClick={() => handleNavigation("next")}
          disabled={currentBoardId >= highestRank}
          className="nav-button"
          style={{ cursor: "pointer" }}
        >
          <ChevronRight size={24} />
        </button>
      </div>
    </>
  );
};

export default BoardTree;
