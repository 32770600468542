import React, { useEffect, useState } from "react";
import QuickBalance from "../../components/Dashboard/DashboardTiles";
import JoiningChart from "../../components/Dashboard/JoiningChart";
import TeamMembers from "../../components/Dashboard/TeamMembers";
import TeamMembersEarningSection from "../../components/Dashboard/TeamPerformance";
import EarningsExpenses from "../../components/Dashboard/Earnings";
import { ApiHook } from "../../hooks/apiHook";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RankComponent from "../../components/Dashboard/RankComponent";
import { updateTourOpen } from "../../store/reducers/userReducer";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import ReEntryUsers from "../../components/Dashboard/ReEntryUsers";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import SingleBoard from "../../components/Dashboard/SingleBoard";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.dashboard?.appLayout?.user);
  const isTourOpen = useSelector((state) => state.user?.isTourOpen);
  const isDemoVisitor = useSelector((state) => state.user?.isDemoVisitor);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isTourClosed, setIsTourClosed] = useState(false);
  // --------------------------------------------- API -------------------------------------------------
  const endTutorialMutation = ApiHook.CallEndTutorial(setIsTourClosed);

  const dashboardContent = ApiHook.CallDashboardRight();

  console.log(dashboardContent?.data, "dashboardContent");

  const closeTour = () => {
    driverObj.destroy();
    localStorage.setItem("tutorial", "1");
    dispatch(updateTourOpen(false));
    if (!isTourClosed) {
      endTutorialMutation.mutateAsync();
    }
  };

  useEffect(() => {
    // Function to check if mobile view
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth <= 768); // Assuming 768px as mobile breakpoint
    };

    // Call the function on initial load
    checkMobileView();

    // Add event listener to update view on window resize
    window.addEventListener("resize", checkMobileView);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", checkMobileView);
  }, []);

  useEffect(() => {
    if (
      isTourOpen.status &&
      isDemoVisitor &&
      localStorage?.getItem("tutorial") === "0"
    ) {
      setTimeout(() => {
        driverObj.drive();
      }, 1000);
    }
  }, [isTourOpen, isDemoVisitor]);

  const steps = [
    {
      element: '[data-tut="dashboardTiles"]',
      popover: {
        description: t("tutorialStep1"),
        side: "right",
      },
    },
    {
      element: '[data-tut="rightSection"]',
      popover: {
        description: t("tutorialStep2"),
        side: "left",
      },
    },
    {
      element: isMobileView
        ? '[data-tut="mobile-navbar"]'
        : '[data-tut="side-navbar"]',
      popover: {
        description: t("tutorialStep3"),
        side: "right",
      },
    },
    ...(isMobileView
      ? []
      : [
          {
            element: '[data-tut="expand-side-menu"]',
            popover: {
              description: t("tutorialStep4"),
              side: "right",
            },
          },
        ]),
    {
      element: '[data-tut="profile"]',
      popover: {
        description: t("tutorialStep5"),
        side: "bottom",
      },
    },
    // {
    //   popover: {
    //     description: t('tutorialStep6'),
    //     side:"top"
    //   }
    // }
  ];

  const driverObj = driver({
    nextBtnText: "→",
    prevBtnText: "←",
    doneBtnText: t("close"),
    allowClose: false,
    animate: false,
    showProgress: true,
    progressText: "{{current}}",
    onDestroyed: closeTour,
    onPopoverRender: (popover, { config, state }) => {
      const isLastStep = state.activeIndex === config.steps.length - 1;
      const popoverElements = document.getElementsByClassName("driver-popover");
      const expandIcon = document.getElementsByClassName(
        "driver-popover-footer"
      );

      if (state.activeIndex === 1 && popoverElements.length > 0) {
        const popoverElement = popoverElements[0];
        popoverElement.style.marginTop = "20px";
      }
      if (state.activeIndex === 3 && expandIcon.length > 0) {
        const expandElement = expandIcon[0];
        expandElement.style.marginBottom = "12px";
      }
      if (!isLastStep) {
        const skipButton = document.createElement("button");
        skipButton.innerText = "skip";
        popover.footerButtons.appendChild(skipButton);

        skipButton.addEventListener("click", () => {
          driverObj.destroy();
        });
      }
    },
    showButtons: ["next", "previous", "close"],
    steps: steps,
  });
  // ---------------------
  // const cardColors = [
  //   "#DE3C54",
  //   "#33A9E0",
  //   "#9B1D22",
  //   "#2E7A9C",
  //   "#514255",
  //   "#11A58D",
  //   "#292D36",
  //   "#E23620",
  //   "#56AE36",
  //   "#526959",
  //   "#46639B",
  //   "#44BC5B",
  // ];
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const [copySuccess, setCopySuccess] = useState("");
  const [copySuccessAni, setCopySuccessAni] = useState(false);

  const copyReplicaToClipboard = () => {
    const link = dashboardContent?.data?.replica?.link;

    if (!link) {
      setCopySuccess("Link not available");
      return;
    }

    // Check if Clipboard API is supported
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          setCopySuccessAni(true);
          setCopySuccess("Link copied!");
          setTimeout(() => setCopySuccessAni(false), 3000);
        })
        .catch((err) => {
          console.error("Clipboard write failed:", err);
          setCopySuccess("Copy unavailable");
          setCopySuccessAni(false);
        });
    } else {
      // Fallback method for unsupported browsers
      const textArea = document.createElement("textarea");
      textArea.value = link;
      document.body.appendChild(textArea);
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        setCopySuccessAni(true);
        setCopySuccess(successful ? "Link copied!" : "Copy failed");
        setTimeout(() => setCopySuccessAni(false), 3000);
      } catch (err) {
        console.error("Fallback copy failed:", err);
        setCopySuccess("Copy unavailable");
        setCopySuccessAni(false);
      }

      // Remove the temporary text area
      document.body.removeChild(textArea);
    }
  };

  return (
    <>
      <div className="center_content_head "></div>
      <div className="text-center">
        <div
          className="refferel-Link_dashboard "
          onClick={copyReplicaToClipboard}
        >
          <span className="icon_copy">
            <FontAwesomeIcon icon={faCopy} />
          </span>
          <span className="copy_slide">
            {copySuccessAni ? copySuccess : "REFERRAl LINK"}
          </span>
        </div>
      </div>
      <div className="joining_Teammbr_section mt-0">
        <div className="row">
          {dashboardContent?.data?.boardArrays?.map((val, ind) => (
            <div key={ind} className="col-xl-3 col-md-4 col-sm-6">
              {val.map((val, ind) => {
                return (
                  <SingleBoard
                    val={val}
                    ind={ind}
                    currentBoard={dashboardContent?.data?.currentBoard}
                    perpectualCycle={dashboardContent?.data?.perpetualCycle}
                  />
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
