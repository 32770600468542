import React from "react";
import { Button, Modal } from "react-bootstrap";
import { ApiHook } from "../../../hooks/apiHook";
import { t } from "i18next";

const ConfirmModal = ({
  confirmModal,
  handleCloseConfirmModal,
  handleCloseModal,
  currentBoardId,
}) => {
  const insertIntoBoard = ApiHook.CallInsertBoard();

  const confirmBoardEntry = () => {
    const data = {
      board: currentBoardId,
    };
    insertIntoBoard.mutate(data);
  };

  return (
    <div>
      <Modal show={confirmModal} onHide={handleCloseConfirmModal} centered>
        <div className="close-btn-parent">
          <button className="close-cross-btn" onClick={handleCloseModal}>
            X
          </button>
        </div>
        <Modal.Body>
          <div className="mb-5"></div>
          <div className="user-detail-modal mb-8">
            <div>{t("confirmBoardEntry")} </div>
            <br />
            <div style={{ color: "red" }}>{t("afterActionText")}</div>
          </div>
          <div
            className="whatsapp-button-container d-flex justify-content-between"
            style={{ marginTop: "5em" }}
          >
            <Button
              variant="secondary"
              className="btn btn_confirm_entry_from_board"
              onClick={confirmBoardEntry}
              disabled={insertIntoBoard.isLoading}
            >
              {insertIntoBoard.isLoading ? t("loading") : t("confirmEntry")}
            </Button>
            <Button
              variant="primary"
              className="modal-foot-button-danger deney-btn"
              onClick={handleCloseConfirmModal}
            >
              {t("Deny")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
