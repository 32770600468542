import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "../../../assests/css/board.css";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Loader from "react-js-loader";
import { Button, Modal } from "react-bootstrap";
import ConfirmModal from "./ConfirmModal";

const TreeNode = ({ node, onNodeHover, onNodeLeave, image }) => {
  console.log({ image });
  const { t } = useTranslation();
  const nodeRef = useRef(null);

  const handleMouseEnter = () => {
    const rect = nodeRef.current.getBoundingClientRect();
    onNodeHover(node.details, rect);
  };
  return (
    <li className={node.position}>
      <a
        ref={nodeRef}
        href="#"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={onNodeLeave}
      >
        <div style={{ position: "relative" }}>
          <img
            style={{ width: "80px", height: "80px" }}
            src={
              image != ""
                ? image
                : "http://192.168.10.52:1660/assets/images/board.png"
            }
            alt={node.name}
          />
          <p
            style={{
              position: "absolute",
              top: "36%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: node.payment_status == 0 ? "red" : "green",
              width: "80px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "8ch",
            }}
          >
            {node.name}
          </p>
        </div>
      </a>
      {node.children && node.children.length > 0 && (
        <ul>
          {node.children.map((child) => (
            <TreeNode
              key={child.id}
              node={child}
              onNodeHover={onNodeHover}
              onNodeLeave={onNodeLeave}
              image={image}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

TreeNode.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.object),
    details: PropTypes.object,
  }).isRequired,
  onNodeHover: PropTypes.func.isRequired,
  onNodeLeave: PropTypes.func.isRequired,
};

const BoardTreeComponent = ({
  treeData,
  currentBoardId,
  image,
  confirm,
  currentUserId,
}) => {
  console.log({ image });
  const queryClient = useQueryClient();
  const updateApprove = ApiHook.CallApproveBoardTree();
  const updateBoardUser = (body) => {
    updateApprove.mutate(body, {
      onSuccess: (res) => {
        if (res.status) {
          queryClient.invalidateQueries({ queryKey: ["get-board-tree"] });
          toast.success(t(res?.data));
        } else {
          toast.error(
            res?.description
              ? t(res?.description)
              : t("errorOccuredPleaseTryAgain")
          );
        }
      },
    });
  };

  const [userDetails, setUserDetails] = useState(null);
  const { t } = useTranslation();
  const tooltipTimeoutRef = useRef(null);

  // ----------modal--------
  const [showModal, setShowModal] = useState();
  const handleShowModal = (data) => {
    console.log(data);
    setUserDetails(data);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setUserDetails(null);
  };
  // ---------modal-end------------

  // ----- Confirm Entry Modal ----- //
  const [confirmModal, setConfirmModal] = useState();
  const handleConfirmModal = () => {
    setConfirmModal(true); // Open the modal
  };
  const handleCloseConfirmModal = () => {
    setConfirmModal(false); // Close the modal
  };
  // ----- Confirm Entry Modal End ----- //
  useEffect(() => {
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };
  }, []);

  if (updateApprove.isLoading) {
    return (
      <div className="centered-loader">
        <Loader
          type="bubble-scale"
          bgColor={"#954cea"}
          color={"#ffffff"}
          size={50}
        />
      </div>
    );
  }

  return (
    <>
      <div className="tree_view_parent_box">
        {confirm && (
          <div className="text-center">
            <button
              className="btn btn_confirm_entry_from_board"
              onClick={handleConfirmModal}
            >
              {t("confirmEntry")}
            </button>
          </div>
        )}
        <div className="tree_view_headings">
          <span className="span-title">ALPHA</span>
          <span className="span-title">OMEGA</span>
          <span className="span-title">BLESSED</span>
          <span className="span-title">OMEGA</span>
          <span className="span-title">ALPHA</span>
        </div>
        <div className="board_tree">
          {treeData ? (
            <div className="board_shieds_container">
              {/* left last leg */}
              {treeData?.children?.map((val, ind) => {
                if (val?.position !== "left_child") {
                  return null;
                }

                if (val?.children?.length >= 0) {
                  return (
                    <>
                      <div
                        className={`board_shieds_last-leg ${
                          val.children.length === 0 ? "min-width_last-leg" : ""
                        }`}
                      >
                        {val.children.map((childVal, childInd) => (
                          <div
                            key={childInd}
                            className="board_shieds omega_boeard"
                            style={
                              childVal.position === "left_child"
                                ? { order: "1" }
                                : {}
                            }
                            onClick={() => {
                              handleShowModal(childVal);
                            }}
                          >
                            <h6
                              style={
                                childVal.payment_status === 0
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {childVal.name}
                            </h6>
                            <img
                              src={
                                image
                                  ? image
                                  : "/images/repurchase-Blue_shield.png"
                              }
                              alt=""
                            />
                          </div>
                        ))}
                      </div>
                      <div
                        className="alpaha_board"
                        key={ind}
                        onClick={() => {
                          handleShowModal(val);
                        }}
                      >
                        <div className="board_shieds left-leg">
                          <h6
                            style={
                              val.payment_status === 0 ? { color: "red" } : {}
                            }
                          >
                            {val.name}
                          </h6>
                          <img
                            src={
                              image
                                ? image
                                : "/images/repurchase-Blue_shield.png"
                            }
                            alt="Blue Shield"
                          />
                        </div>
                      </div>
                    </>
                  );
                }
                return (
                  <>
                    <div className="min-width_last-leg"></div>
                    <div className="min-width_last-leg"></div>
                  </>
                );
              })}

              {/* center */}
              <div
                className="blessed_board"
                onClick={() => {
                  handleShowModal(treeData);
                }}
              >
                <div className="board_shieds main">
                  <h6
                    style={treeData.payment_status == 0 ? { color: "red" } : {}}
                  >
                    {treeData.name}
                  </h6>
                  <img
                    src={image ? image : "/images/repurchase-Blue_shield.png"}
                    alt=""
                  />
                </div>
              </div>
              {treeData?.children?.map((val, ind) => {
                if (val?.position !== "right_child") {
                  return null;
                }

                if (val?.children?.length >= 0) {
                  return (
                    <>
                      {/* right middle */}
                      <div
                        className="alpaha_board"
                        key={ind}
                        onClick={() => {
                          handleShowModal(val);
                        }}
                      >
                        <div className="board_shieds left-leg">
                          <h6
                            style={
                              val.payment_status == 0 ? { color: "red" } : {}
                            }
                          >
                            {val.name}
                          </h6>
                          <img
                            src={
                              image
                                ? image
                                : "/images/repurchase-Blue_shield.png"
                            }
                            alt="Blue Shield"
                          />
                        </div>
                      </div>
                      <div
                        className={`board_shieds_last-leg ${
                          val.children.length == 0 ? "min-width_last-leg" : ""
                        }`}
                      >
                        {val?.children?.map((val) => {
                          return (
                            <div
                              className="board_shieds omega_boeard"
                              style={
                                val?.position === "left_child"
                                  ? { order: "1" }
                                  : {}
                              }
                              onClick={() => {
                                handleShowModal(val);
                              }}
                            >
                              <h6
                                style={
                                  val.payment_status == 0
                                    ? { color: "red" }
                                    : {}
                                }
                              >
                                {val.name}
                              </h6>
                              <img
                                src={
                                  image
                                    ? image
                                    : "/images/repurchase-Blue_shield.png"
                                }
                                alt=""
                              />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  );
                }

                return (
                  <>
                    <div className="min-width_last-leg"></div>
                    <div className="min-width_last-leg"></div>
                  </>
                );
              })}
              {treeData?.children.length ==1 &&   ( <>
                    <div className="min-width_last-leg"></div>
                    <div className="min-width_last-leg"></div>
                  </>)}
            </div>
          ) : (
            <div className="nodata_image_tree">
              <img src="/images/nodatatree.png" alt="" />
              <h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-alert-circle"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="12" y1="8" x2="12" y2="12"></line>
                  <line x1="12" y1="16" x2="12" y2="16"></line>
                </svg>
                No Data Found
              </h6>
            </div>
          )}
          <Modal show={showModal} onHide={handleCloseModal} centered>
            <div className="close-btn-parent">
              <button className="close-cross-btn" onClick={handleCloseModal}>
                X
              </button>
            </div>
            <Modal.Body>
              <div className="dashboard_modal_image-profile">
                <img
                  src={
                    userDetails?.details?.dataValues?.image
                      ? userDetails?.details?.dataValues?.image
                      : image
                      ? image
                      : "/images/repurchase-Blue_shield.png"
                  }
                  alt=""
                />
              </div>
              <div className="user-detail-modal mb-3">
                <div>{userDetails?.name}</div>
                <div>
                  {userDetails?.details?.dataValues?.name
                    ? userDetails?.details?.dataValues?.name +
                      userDetails?.details?.dataValues?.secondName
                      ? userDetails?.details?.dataValues?.secondName
                      : ""
                    : ""}
                </div>
                <div>
                  {userDetails?.details?.dataValues?.mobile
                    ? userDetails?.details?.dataValues?.mobile
                    : ""}
                </div>
                <div>
                  {userDetails?.details?.email
                    ? userDetails?.details?.email
                    : ""}
                </div>
                {/* <div>+5585986888786</div> */}
              </div>
              <div className="whatsapp-button-container">
                <Button
                  variant="secondary"
                  className="button-success-whatsapp"
                  onClick={() => {
                    window.open(
                      userDetails?.details?.dataValues?.whatsappNumber
                        ? `https://wa.me/${userDetails?.details?.dataValues?.whatsappNumber}`
                        : "",
                      "_blank"
                    );
                  }}
                >
                  <>
                    <img
                      src="/images/whatsapp.png"
                      alt="WhatsApp Logo"
                      className="modal_icon_media"
                    />
                    {t("contactUs")}
                  </>
                </Button>
              </div>
              <div className="sponser-detail-modal mb-3 mt-3">
                <div className="strong-text">{t("sponsor")}</div>
                <div className="nml-text">
                  {userDetails?.details?.sponsorUsername
                    ? userDetails?.details?.sponsorUsername
                    : ""}
                </div>
                <div className="nml-text">
                  {userDetails?.details?.sponsorDetails?.name
                    ? userDetails?.details?.sponsorDetails?.name +
                      userDetails?.details?.sponsorDetails?.secondName
                      ? userDetails?.details?.sponsorDetails?.secondName
                      : ""
                    : ""}
                </div>
                <div className="strong-text">
                  {userDetails?.details?.sponsorDetails?.mobile
                    ? userDetails?.details?.sponsorDetails?.mobile
                    : ""}
                </div>
              </div>
              <div className="whatsapp-button-container mb-3">
                <Button
                  variant="secondary"
                  className="button-success-whatsapp"
                  onClick={() => {
                    window.open(
                      userDetails?.details?.sponsorDetails?.whatsappNumber
                        ? `https://wa.me/${userDetails?.details?.sponsorDetails?.whatsappNumber}`
                        : "",
                      "_blank"
                    );
                  }}
                >
                  <>
                    <img
                      src="/images/whatsapp.png"
                      alt="WhatsApp Logo"
                      className="modal_icon_media"
                    />
                    {t("contactUs")}
                  </>
                </Button>
              </div>
            </Modal.Body>

            {userDetails?.payment_status == 0 &&
              userDetails?.board_leader == currentUserId && (
                <Modal.Footer>
                  <div className="w-100 d-flex justify-content-end">
                    <button
                      type="button"
                      class="modal-foot-button-succuss btn btn-secondary"
                      onClick={() => {
                        const data = {
                          board_id: currentBoardId,
                          board_user_id: userDetails?.id,
                        };
                        updateBoardUser(data);
                      }}
                      disabled={updateApprove.isLoading}
                    >
                      {updateApprove.isLoading ? t("loading") : t("approve")}
                    </button>
                  </div>
                </Modal.Footer>
              )}
          </Modal>
          {/* confirm modal */}
          <ConfirmModal
            confirmModal={confirmModal}
            handleCloseConfirmModal={handleCloseConfirmModal}
            handleCloseModal={handleCloseModal}
            currentBoardId={currentBoardId}
          />
          <div className="board_view_bottom">
            <img
              style={{ width: "260px", height: "90px" }}
              src={`/images/${currentBoardId}.png`}
              alt="Description"
            />
          </div>
        </div>
      </div>
    </>
  );
};

BoardTreeComponent.propTypes = {
  treeData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.object),
    details: PropTypes.object,
  }),
};

export default BoardTreeComponent;
